import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import logo from "./logo.svg";
import "../styles/main.css";
import Menu from "../components/Menu";
import {
  AbsoluteCenter,
  Box,
  Card,
  CardBody,
  Center,
  Divider,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { LayoutGroup, MotionConfig, motion, useInView } from "framer-motion";
import ContactForm from "../components/ContactForm";
import MyCard from "../components/MyCard";
import MenuItem from "../components/MenuItem";
import Impressum from "../components/Impressum";
import Slider from "../components/Slider";
import Slide from "../components/Slide";
import ImageProducts from "../assets/card_image_products.jpg";
import ImageCommodities from "../assets/card_image_commodities.jpg";
import ImageServices from "../assets/card_image_services.jpg";
import ImageKohle from "../assets/Kohle.jpg";
import ImageMetal from "../assets/Metall.jpg";
import ImageCity from "../assets/City.jpg";
import ImageChart from "../assets/Chart.jpg";
import ImageCoke from "../assets/Coke.jpg";
import ImageFertilizer from "../assets/Fertilizer.jpg";
import ImageFertilizer2 from "../assets/Fertilizer2.jpg";
import ImageGuinness from "../assets/Guinness.jpg";
import ImageNetwork from "../assets/Network.jpg";
import ImageOffice from "../assets/Office.jpg";
import ImageOil from "../assets/Oil.jpg";
import ImagePasta from "../assets/Pasta.jpg";
import ImageSpaghetti from "../assets/Spaghetti.jpg";
import ImageCar from "../assets/Car.jpg";
import ImagePump from "../assets/Pump.jpg";
import ImageShip from "../assets/Ship.jpg";
import ImageTruck from "../assets/Truck.jpg";
import ImageVegetables from "../assets/Vegetables.jpg";
import ImageWheat from "../assets/Wheat.jpg";
import ImageWheat2 from "../assets/Wheat2.jpg";
import MobileSlider from "../components/MobileSlider";
import NavDrawer from "../components/NavDrawer";
import { ReactComponent as Burger } from "../assets/burger.svg";
import MenuBar from "../components/MenuBar";
import ListElement from "../components/ListElement";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

function Main() {
  const [isTabletOrMobile] = useMediaQuery("(max-width: 768px)");
  const homeRef = useRef<HTMLDivElement>(null);
  const offerRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const [cardOrder, setCardOrder] = useState<number[]>([1, 2]);
  const [currentSlideCommodities, setCurrentSlideCommodities] =
    useState<number>(0);
  const [currentSlideProducts, setCurrentSlideProducts] = useState<number>(0);
  const [currentSlideServices, setCurrentSlideServices] = useState<number>(0);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const elementInView = new Map([
    ["home", useInView(homeRef, { amount: 0.9 })],
    ["offer", useInView(offerRef, { amount: 0.6 })],
    ["about", useInView(aboutRef, { amount: 0.7 })],
    ["contact", useInView(contactRef, { amount: 0.7 })],
  ]);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpenNav === true) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpenNav]);

  useEffect(() => {
    if (isTabletOrMobile === false) {
      setIsOpenNav(false);
    }
  }, [isTabletOrMobile]);

  return (
    <>
      {isOpenNav ? (
        <></>
      ) : (
        <div className="burger-button" onClick={() => setIsOpenNav(true)}>
          <Burger className="burger"></Burger>
        </div>
      )}
      <motion.div
        className="motion-drawer-wrapper"
        animate={{ x: isOpenNav ? 0 : "100vw" }}
        transition={{ ease: "easeIn" }}
        initial={{ x: "100vw" }}
      >
        <NavDrawer isOpen={isOpenNav} setIsOpen={setIsOpenNav}>
          <nav>
            <a
              onClick={() => {
                setIsOpenNav(false);
                homeRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              Home
            </a>
            <a
              onClick={() => {
                setIsOpenNav(false);
                aboutRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              About us
            </a>
            <a
              onClick={() => {
                setIsOpenNav(false);
                offerRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              What we offer
            </a>
            <a
              onClick={() => {
                setIsOpenNav(false);
                contactRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              Contact
            </a>
            <Link
              to={"impressum"}
              onClick={() => {
                document.body.classList.remove("no-scroll");
                setIsOpenNav(false);
              }}
            >
              Impressum
            </Link>
          </nav>
        </NavDrawer>
      </motion.div>

      {isTabletOrMobile ? (
        <></>
      ) : (
        <MenuBar isHeroInView={elementInView.get("home") ?? true}>
          <nav>
            <a
              className={
                elementInView.get("home") === true ? "nav-in-view" : ""
              }
              onClick={() => {
                homeRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              Home
            </a>
            <a
              className={
                elementInView.get("about") === true ? "nav-in-view" : ""
              }
              onClick={() => {
                aboutRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              About us
            </a>
            <a
              className={
                elementInView.get("offer") === true ? "nav-in-view" : ""
              }
              onClick={() => {
                offerRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              What we offer
            </a>
            <a
              className={
                elementInView.get("contact") === true ? "nav-in-view" : ""
              }
              onClick={() => {
                contactRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              Contact
            </a>
            <Link to={"impressum"}>Impressum</Link>
          </nav>
        </MenuBar>
      )}
      <div ref={homeRef} className="bg">
        <div className="page-hero">
          <div className="wrapper-content">
            <Center>
              <motion.h1
                className="title1"
                initial={{ x: "-100vw" }}
                animate={{ x: 0 }}
                transition={{
                  duration: 0.8,
                  type: "spring",
                  delay: 0.5,
                }}
              >
                Alliance of Product Exchanges
              </motion.h1>
            </Center>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8, duration: 0.8 }}
            >
              <Center width={"80%"} marginInline={"auto"}>
                {/* <Divider borderColor={""} marginBlock={"1rem"} /> */}
                <div
                  style={{
                    height: "2px",
                    width: "100%",
                    backgroundImage:
                      "linear-gradient(90deg, transparent, var(--gray-200), transparent)",
                  }}
                ></div>
              </Center>
            </motion.div>
            <Center>
              <motion.h1
                className="title2"
                initial={{ x: "100vw" }}
                animate={{ x: 0 }}
                transition={{ duration: 0.8, type: "spring", delay: 0.5 }}
              >
                APE 50 Trade Kft. | APE 50 TRADING FZCO
              </motion.h1>
            </Center>
          </div>
          <div className="row-3">
            <div
              className="arrow-down"
              onClick={() => {
                aboutRef.current?.scrollIntoView({ behavior: "smooth" });
              }}
            ></div>
          </div>
        </div>

        <div ref={aboutRef} className="page-welcome">
          <div className="content-welcome">
            <h1>Welcome!</h1>
            <p>
              At APE 50 Trade, we stand as the quintessential emblem of
              international wholesaling. <br />
              <br /> With an unwavering commitment to excellence, a global
              footprint that transcends borders, and an extensive repertoire of
              products. We are your preeminent partner for navigating the
              intricate tapestry of the international market.
            </p>
            <p>
              <br />
              We are a consulting firm with a steadfast commitment to excellence
              and a global reach that extends across continents. Our mission is
              to provide unparalleled consulting services to organizations
              worldwide, empowering them to navigate the complexities of the
              global business landscape with expertise and insight.
            </p>
          </div>
        </div>

        <div className="page-introduction" ref={offerRef}>
          {isTabletOrMobile
            ? SmallScreenIntroduction()
            : BigScreenIntroduction()}
        </div>
        <div className="page-contact" ref={contactRef}>
          <div className="section-contact">
            <div className="wrapper-contact-form">
              <ContactForm></ContactForm>
            </div>
            <div className="headquarter">
              <div>
                <Text fontSize={"xl"} fontWeight={"600"}>
                  Headquarter
                </Text>
                <Text fontSize={"lg"} fontWeight={"500"}>
                  Conrad Business Centre
                  <br />
                  Trade Centre First
                  <br />
                  Office 1004
                  <br />
                  485054 Dubai UAE
                </Text>
              </div>
              <div>
                <Text fontSize={"xl"} fontWeight={"600"}>
                  Contact
                </Text>
                <Text fontSize={"lg"} fontWeight={"500"}>
                  <a href="mailto:office@ape50trading.eu">
                    office@ape50trading.eu
                  </a>
                  <br />
                  <a href="tel:+97144468619">+97144468619</a>
                </Text>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </>
  );

  function SmallScreenIntroduction() {
    return (
      <>
        <MobileSlider
          currentSlide={currentSlideCommodities}
          setCurrentSlide={setCurrentSlideCommodities}
          numberElements={5}
        >
          {SlidesCommodities(
            currentSlideCommodities,
            setCurrentSlideCommodities,
            "small"
          )}
        </MobileSlider>

        {/* <MobileSlider
          currentSlide={currentSlideProducts}
          setCurrentSlide={setCurrentSlideProducts}
          numberElements={3}
        >
          {SlidesProducts(
            currentSlideProducts,
            setCurrentSlideProducts,
            "small"
          )}
        </MobileSlider> */}

        <MobileSlider
          currentSlide={currentSlideServices}
          setCurrentSlide={setCurrentSlideServices}
          numberElements={4}
        >
          {SlidesServices(
            currentSlideServices,
            setCurrentSlideServices,
            "small"
          )}
        </MobileSlider>
      </>
    );
  }

  function BigScreenIntroduction() {
    return (
      <>
        <div className="wrapper-cards">
          <MotionConfig transition={{ bounce: 0 }}>
            <motion.div
              className="card-container"
              style={{
                order: cardOrder[0],
                flexGrow: cardOrder[0] === 1 ? 1.5 : 1,
              }}
              onClick={() => {
                setCardOrder([1, 0, 2]);
                //Beim Wechsel der Kategorie soll der letzte betrachtete Slide nicht gespeichert werden
                setCurrentSlideCommodities(0);
                setCurrentSlideProducts(0);
                setCurrentSlideServices(0);
              }}
              key={1}
              layout
            >
              <MyCard
                style={{
                  backgroundImage: `url(${ImageCommodities})`,
                }}
                inFocus={cardOrder[0] === 1 ? true : false}
              >
                <h1 className="card-title">Our commodities</h1>
              </MyCard>
            </motion.div>
            {/* <motion.div
              className="card-container"
              style={{
                order: cardOrder[1],
                flexGrow: cardOrder[1] === 1 ? 1.5 : 1,
              }}
              onClick={() => {
                setCardOrder([0, 1, 2]);
                //Beim Wechsel der Kategorie soll der letzte betrachtete Slide nicht gespeichert werden
                setCurrentSlideCommodities(0);
                setCurrentSlideProducts(0);
                setCurrentSlideServices(0);
              }}
              key={2}
              layout
            >
              <MyCard
                style={{
                  backgroundImage: `url(${ImageProducts})`,
                }}
                inFocus={cardOrder[1] === 1 ? true : false}
              >
                <h1 className="card-title">Our Products</h1>
              </MyCard>
            </motion.div> */}
            <motion.div
              className="card-container"
              style={{
                order: cardOrder[2],
                flexGrow: cardOrder[2] === 1 ? 1.5 : 1,
              }}
              onClick={() => {
                setCardOrder([0, 2, 1]);
                //Beim Wechsel der Kategorie soll der letzte betrachtete Slide nicht gespeichert werden
                setCurrentSlideCommodities(0);
                setCurrentSlideProducts(0);
                setCurrentSlideServices(0);
              }}
              key={3}
              layout
            >
              <MyCard
                style={{
                  backgroundImage: `url(${ImageServices})`,
                }}
                inFocus={cardOrder[2] === 1 ? true : false}
              >
                <h1 className="card-title">Our services</h1>
              </MyCard>
            </motion.div>
          </MotionConfig>
        </div>
        {/* Commodities */}
        {cardOrder[0] === 1 ? (
          <Slider
            currentSlide={currentSlideCommodities}
            setCurrentSlide={setCurrentSlideCommodities}
            numberElements={4}
          >
            {SlidesCommodities(
              currentSlideCommodities,
              setCurrentSlideCommodities,
              "large"
            )}
          </Slider>
        ) : (
          <></>
        )}
        {/* Products
        {cardOrder[1] === 1 ? (
          <Slider
            currentSlide={currentSlideProducts}
            setCurrentSlide={setCurrentSlideProducts}
            numberElements={2}
          >
            {SlidesProducts(
              currentSlideProducts,
              setCurrentSlideProducts,
              "large"
            )}
          </Slider>
        ) : (
          <></>
        )} */}
        {/* Services */}
        {cardOrder[2] === 1 ? (
          <Slider
            currentSlide={currentSlideServices}
            setCurrentSlide={setCurrentSlideServices}
            numberElements={3}
          >
            {SlidesServices(
              currentSlideServices,
              setCurrentSlideServices,
              "large"
            )}
          </Slider>
        ) : (
          <></>
        )}
      </>
    );
  }
}

function SlidesProducts(
  currentSlideProducts: number,
  setCurrentSlideProducts: Function,
  size: "small" | "large"
) {
  return (
    <>
      {size === "small" ? (
        <Slide
          size="cover"
          coverBgImage={ImageProducts}
          index={0}
          leftImage=""
          rightImage=""
          currentSlide={currentSlideProducts}
          setCurrentSlide={setCurrentSlideProducts}
        >
          <h1 className="mobile-card-title1">Our Products</h1>
        </Slide>
      ) : null}
      <Slide
        size={size}
        currentSlide={currentSlideProducts}
        setCurrentSlide={setCurrentSlideProducts}
        index={1}
        leftImage={ImageGuinness}
        rightImage={ImageCoke}
      >
        <ul>
          <ListElement>
            Beer and similar alcoholic fermented bevereges
          </ListElement>
          <ListElement>Distilled drinks</ListElement>
          <ListElement>Non alcoholic bevereges</ListElement>
        </ul>
      </Slide>
      <Slide
        size={size}
        currentSlide={currentSlideProducts}
        setCurrentSlide={setCurrentSlideProducts}
        index={2}
        leftImage={ImagePasta}
        rightImage={ImageSpaghetti}
      >
        <h2 className="card-title">Other products</h2>
        <ul>
          <ListElement>Pasta</ListElement>
          <ListElement>And many more</ListElement>
        </ul>
      </Slide>
    </>
  );
}

function SlidesCommodities(
  currentSlideCommodities: number,
  setCurrentSlideCommodities: Function,
  size: "small" | "large"
) {
  return (
    <>
      {size === "small" ? (
        <Slide
          size="cover"
          coverBgImage={ImageCommodities}
          index={0}
          leftImage=""
          rightImage=""
          currentSlide={currentSlideCommodities}
          setCurrentSlide={setCurrentSlideCommodities}
        >
          <h1 className="mobile-card-title1">Our Commodities</h1>
        </Slide>
      ) : null}
      <Slide
        size={size}
        index={1}
        currentSlide={currentSlideCommodities}
        setCurrentSlide={setCurrentSlideCommodities}
        leftImage={ImageKohle}
        rightImage={ImageMetal}
      >
        <h2 className="card-title">Coal and Metals</h2>
        <ul>
          <ListElement>Energy coal</ListElement>
          <ListElement>Coking coal</ListElement>
          <ListElement>Construction steel</ListElement>
          <ListElement>Copper powder</ListElement>
          <ListElement>Aluminum powder</ListElement>
          <ListElement>Ferroalloys</ListElement>
        </ul>
      </Slide>
      <Slide
        size={size}
        index={2}
        currentSlide={currentSlideCommodities}
        setCurrentSlide={setCurrentSlideCommodities}
        leftImage={ImagePump}
        rightImage={ImageCar}
      >
        <h2 className="card-title">Oil and Fuel</h2>
        <ul>
          <ListElement>Oil</ListElement>
          <ListElement>Diesel EN590 10ppm</ListElement>
          <ListElement>Petrol E5 E10</ListElement>
        </ul>
      </Slide>
      <Slide
        size={size}
        index={3}
        currentSlide={currentSlideCommodities}
        setCurrentSlide={setCurrentSlideCommodities}
        leftImage={ImageFertilizer2}
        rightImage={ImageFertilizer}
      >
        <h2 className="card-title">Fertilizers</h2>
        <ul>
          <ListElement>Urea {"(granular)"} fertilizers</ListElement>
          <ListElement>Ammonium nitrate fertilizers</ListElement>
        </ul>
      </Slide>

      <Slide
        size={size}
        index={4}
        currentSlide={currentSlideCommodities}
        setCurrentSlide={setCurrentSlideCommodities}
        leftImage={ImageWheat}
        rightImage={ImageWheat2}
      >
        <h2 className="card-title">Wheat</h2>
        <ul>
          <ListElement>Whole grain wheat</ListElement>
          <ListElement>Wheat flour</ListElement>
        </ul>
      </Slide>
      {/* <Slide
        size={size}
        index={5}
        currentSlide={currentSlideCommodities}
        setCurrentSlide={setCurrentSlideCommodities}
        leftImage={ImageOil}
        rightImage={ImageVegetables}
      >
        <h2 className="card-title">Vegetable oils and Vegetables</h2>
        <ul>
          <ListElement>
            Sunflower oil {"(refined and cold pressed)"}
          </ListElement>
          <ListElement>Rapeseed oil {"(refined and cold pressed)"}</ListElement>
          <ListElement>Frozen vegetables</ListElement>
          <ListElement>Fresh vegetables</ListElement>
          <ListElement>Mushrooms</ListElement>
        </ul>
      </Slide> */}
    </>
  );
}

function SlidesServices(
  currentSlideServices: number,
  setCurrentSlideServices: Function,
  size: "small" | "large"
) {
  return (
    <>
      {size === "small" ? (
        <Slide
          size="cover"
          coverBgImage={ImageServices}
          index={0}
          leftImage=""
          rightImage=""
          currentSlide={currentSlideServices}
          setCurrentSlide={setCurrentSlideServices}
        >
          <h1 className="mobile-card-title1">Our Services</h1>
        </Slide>
      ) : null}
      <Slide
        size={size}
        currentSlide={currentSlideServices}
        setCurrentSlide={setCurrentSlideServices}
        index={1}
        leftImage={ImageShip}
        rightImage={ImageTruck}
      >
        <h2 className="card-title">Transport and logistics solutions</h2>
        <ul>
          <ListElement>
            We have a broad network of reliable partners in the area of
            transport and logistics
          </ListElement>
          <ListElement>
            Our transport/logistics partners are located throughout Europe, the
            Middle East and Central Asia
          </ListElement>
          <ListElement>
            No matter whether the goods are to be transported by ship, train or
            car - we will find a solution
          </ListElement>
        </ul>
      </Slide>
      <Slide
        size={size}
        currentSlide={currentSlideServices}
        setCurrentSlide={setCurrentSlideServices}
        index={2}
        leftImage={ImageOffice}
        rightImage={ImageNetwork}
      >
        <h2 className="card-title">Agency services</h2>
        <ul>
          <ListElement>
            Do you produce and/or sell valuable raw materials, but are having
            difficulty finding a reliable trading partner in the target area?
          </ListElement>
          <ListElement>
            With our broad trading network, we are able to provide our partners
            with professional support as an international sales representative
          </ListElement>
        </ul>
      </Slide>
      <Slide
        size={size}
        currentSlide={currentSlideServices}
        setCurrentSlide={setCurrentSlideServices}
        index={3}
        leftImage={ImageCity}
        rightImage={ImageChart}
      >
        <h2 className="card-title">Consulting</h2>
        <ul>
          <ListElement>
            Our team of experienced specialists is well positioned to support
            our partners in developing solutions related to commodity trading
            operations in Central and Eastern Europe, Central Asia and the
            Middle East
          </ListElement>
        </ul>
      </Slide>
    </>
  );
}

export default Main;
