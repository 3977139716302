import React, { LegacyRef, ReactNode } from "react";
import "../styles/menuBar.css";

interface IMenuBarProps {
  children: ReactNode;
  isHeroInView: boolean;
}

export default function MenuBar(props: IMenuBarProps) {
  return (
    <div
      style={{
        backgroundColor: props.isHeroInView
          ? "transparent"
          : "var(--primary-800)",
        transition: props.isHeroInView ? "500ms ease-in" : "100ms ease-in",
        boxShadow: props.isHeroInView
          ? "none"
          : "4px 4px 12px 0px rgba(0, 0, 0, 0.35)",
      }}
      className={`menu-bar`}
    >
      <div className="nav-menu-wrapper">{props.children}</div>
    </div>
  );
}
