import { Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import "../styles/datenschutz.css";

export default function Datenschutz() {
  useEffect(() => {
    document.querySelector("body")?.scrollTo(0, 0);
  });
  return (
    <div className="page-ds">
      <div className="wrapper-ds">
        <Text fontSize={"x-large"} fontWeight={600}>
          Declaration of information obligation
        </Text>
        <Text fontSize={"x-large"} fontWeight={600}>
          Data protection
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          In the following data protection declaration we inform you about the
          most important aspects of data processing on our website. We collect
          and process personal data only on the basis of legal provisions
          (General Data Protection Regulation, Telecommunications Act 2003).
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          As soon as you as a user access or visit our website, your IP address,
          start and start and end of the session are recorded. This is for
          technical reasons and therefore represents a legitimate interest
          within the meaning of Art 6 Paragraph 1 lit f GDPR.
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Contact us
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          If you contact us, either via our contact form on our website or by
          email, the data you provide to us will be stored by us for six months
          in order to process your request or in the event of further followup
          questions. Your transmitted data will not be passed on without your
          consent.
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Cookies
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Our website uses so-called cookies. These are small text files that
          are stored on your device using the browser. They do no harm. We use
          cookies to make our offering user-friendly. Some cookies remain stored
          on your device until you delete them. They enable us to recognize your
          browser the next time you visit. If you do not wish this, you can set
          up your browser so that it informs you about the setting of cookies
          and you only allow this in individual cases. If cookies are
          deactivated, the functionality of our website may be restricted.
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Google Fonts
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Our website uses fonts from “Google Fonts”. The service provider of
          this function is:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Google Ireland Limited Gordon House, Barrow Street Dublin 4. Ireland,
          Tel: +353 1 543 1000
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          When you access this website, your browser loads fonts and saves them
          in the cache. Since you, as a visitor to the website, receive data
          from the service provider, Google may set or analyze cookies on your
          computer. The use of “Google Fonts” serves to optimize our service and
          to display content uniformly. This represents a legitimate interest
          within the meaning of Article 6 Paragraph 1 Letter f GDPR. Further
          information about Google Fonts can be found at the following link:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          <a href="https://developers.google.com/fonts/faq">
            https://developers.google.com/fonts/faq
          </a>
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Further information about how Google handles user data can be found in
          the data protection declaration:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          <a href="https://policies.google.com/privacy?hl=de">
            https://policies.google.com/privacy?hl=de
          </a>
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Google also processes the data in the USA, but has refused to do so
          Subject to EU-US Privacy Shield.
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          <a href="https://www.privacyshield.gov/EU-US-Framework">
            https://www.privacyshield.gov/EU-US-Framework
          </a>
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Server log files
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          This website and the associated provider automatically collect
          information as part of so-called “server log files” when you use the
          website. This applies in particular to:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          <ul>
            <li>IP address or hostname</li>
            <li>the browser used</li>
            <li>Duration of stay on the website as well as date and time</li>
            <li>Pages of the website accessed</li>
            <li>Language settings and operating system</li>
            <li>
              “Leaving page” (on which URL did the user leave the website)
            </li>
            <li>ISP (Internet Service Provider)</li>
          </ul>
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          This information collected is not processed on a personal basis or
          associated with personal data. The website operator reserves the right
          to evaluate or check this data if illegal activities become known.
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Your rights as a data subject
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          As a data subject, you generally have the right to:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          <ul>
            <li>Information</li>
            <li>Deletion of data</li>
            <li>Correction of data</li>
            <li>Data portability</li>
            <li>Revocation and objection to data processing</li>
            <li>Limitation</li>
          </ul>
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          If you suspect that data protection law violations have occurred in
          the course of processing your data, you have the opportunity to
          complain to us (ag@ape50trading.eu) or the data protection authority.
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          You can reach us using the following contact details:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Website operator: APE 50 Trade
          <br />
          Telephone number: +436765541712
          <br />
          Email: office@ape50trading.eu
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Source: Data Protection GDPR Generator in cooperation with Lawyer
          Vienna Divorce
        </Text>
      </div>
    </div>
  );
}
