import { MotionConfig, motion } from "framer-motion";
import React, { ReactNode, useState } from "react";
import Slide from "./Slide";
import "../styles/slide.css";
import Dots from "./Dots";

interface IMobileSliderProps {
  children: ReactNode;
  currentSlide: number;
  setCurrentSlide: Function;
  numberElements: number;
}

export default function MobileSlider(props: IMobileSliderProps) {
  return (
    <>
      <motion.div
        className="mobile-slider"
        drag="x"
        dragConstraints={{ right: 0, left: 0 }}
        dragElastic={{
          left: props.currentSlide < props.numberElements - 1 ? 0.8 : 0,
          right: props.currentSlide > 0 ? 0.8 : 0,
        }}
        dragDirectionLock
        onDragEnd={(event, info) => {
          if (
            info.offset.x < -10 &&
            props.currentSlide < props.numberElements - 1
          ) {
            props.setCurrentSlide(props.currentSlide + 1);
          }
          if (info.offset.x > 10 && props.currentSlide > 0) {
            props.setCurrentSlide(props.currentSlide - 1);
          }
        }}
      >
        <div className="mobile-card-container">{props.children}</div>
      </motion.div>
      <Dots
        totalNumber={props.numberElements}
        currentSlide={props.currentSlide}
      ></Dots>
    </>
  );
}
