import React, { ReactNode } from "react";
import { ReactComponent as ArrowRight } from "../assets/keyboard_arrow_right.svg";
import "../styles/listElement.css";

interface IListElementProps {
  children: ReactNode;
}

export default function ListElement(props: IListElementProps) {
  return (
    <div className="list-element">
      <ArrowRight className="list-bullet"></ArrowRight>
      <li>{props.children}</li>
    </div>
  );
}
