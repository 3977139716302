import React, { ReactNode, Ref, forwardRef, useState } from "react";
import "../styles/navDrawer.css";
import { ReactComponent as ButtonClose } from "../assets/ButtonClose.svg";

interface INavDrawerProps {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: Function;
}

export default function NavDrawer(props: INavDrawerProps) {
  return (
    <div className="nav-drawer">
      <div className="nav-drawer-close">
        <ButtonClose
          onClick={() => {
            props.setIsOpen(false);
          }}
        />
      </div>
      <div className="nav-drawer-wrapper">{props.children}</div>
    </div>
  );
}
