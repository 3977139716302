import React from "react";
import "../styles/footer.css";
import { Link } from "react-router-dom";
import MbLogo from "./MbLogo";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-decoration"></div>
      <MbLogo></MbLogo>
      <div className="footer-wrapper">
        <Link to={"impressum"}>Impressum</Link>
        <div>|</div>
        <Link to={"datenschutz"}>Datenschutz</Link>
      </div>
    </div>
  );
}
