import React, { ReactNode, ReactNodeArray } from "react";
import "../styles/dots.css";
import { JsxElement } from "typescript";

interface IDotsProps {
  totalNumber: number;
  currentSlide: number;
}

export default function Dots(props: IDotsProps) {
  var dotsList: ReactNode[] = [];
  var dots = new Map<number, ReactNode>();
  function createDots() {
    for (var i = 0; i < props.totalNumber; i++) {
      dots.set(
        i,
        <div
          className={props.currentSlide === i ? "dot dot-selected" : "dot"}
        ></div>
      );
    }
    for (let [key, value] of dots) {
      dotsList.push(value);
    }
    return dotsList;
  }

  return <div className="dot-wrapper">{createDots()}</div>;
}
