import { MotionConfig, motion } from "framer-motion";
import React, { ReactNode, useState } from "react";
import Slide from "./Slide";
import Dots from "./Dots";

interface ISliderProps {
  children: ReactNode;
  currentSlide: number;
  setCurrentSlide: Function;
  numberElements: number;
}

export default function Slider(props: ISliderProps) {
  return (
    <>
      <motion.div
        className="wrapper-slides"
        drag="x"
        dragConstraints={{ right: 0, left: 0 }}
        dragElastic={{
          left: props.currentSlide < props.numberElements - 1 ? 0.8 : 0,
          right: props.currentSlide > 0 ? 0.8 : 0,
        }}
        transition={{ stiffness: 0 }}
        dragDirectionLock
        onDragEnd={(event, info) => {
          if (
            info.offset.x < -50 &&
            props.currentSlide < props.numberElements - 1
          ) {
            props.setCurrentSlide(props.currentSlide + 1);
          }
          if (info.offset.x > 50 && props.currentSlide > 0) {
            props.setCurrentSlide(props.currentSlide - 1);
          }
        }}
      >
        {props.children}
      </motion.div>
      <Dots
        totalNumber={props.numberElements}
        currentSlide={props.currentSlide}
      ></Dots>
    </>
  );
}
