import { motion } from "framer-motion";
import React, { ReactNode } from "react";
import "../styles/slide.css";
import { Box, Button } from "@chakra-ui/react";
import { ReactComponent as ArrowRight } from "../assets/keyboard_arrow_right.svg";

interface ISlideProps {
  size: "small" | "large" | "cover";
  currentSlide: number;
  setCurrentSlide: Function;
  children?: ReactNode;
  index: number;
  leftImage: string;
  rightImage: string;
  coverBgImage?: string;
}

export default function Slide(props: ISlideProps) {
  function handleClick() {
    if (props.currentSlide > props.index - 1) {
      props.setCurrentSlide(props.currentSlide - 1);
    }
    if (props.currentSlide < props.index - 1) {
      props.setCurrentSlide(props.currentSlide + 1);
    }
  }

  if (props.size === "large") {
    return (
      <motion.div
        className="slide"
        animate={{
          x: `calc(-${props.currentSlide * 100}% - ${props.currentSlide}rem)`,
          scale: props.currentSlide + 1 === props.index ? 1 : 0.85,
          opacity: props.currentSlide + 1 === props.index ? 1 : 0.5,
        }}
        key={props.index}
        onClick={handleClick}
      >
        <div
          className="slide-image-left"
          style={{ backgroundImage: `url(${props.leftImage})` }}
        ></div>
        <div className="slide-body">{props.children}</div>
        <div
          className="slide-image-right"
          style={{ backgroundImage: `url(${props.rightImage})` }}
        ></div>
      </motion.div>
    );
  }
  //TitleCard
  else if (props.size === "cover") {
    return (
      <motion.div
        className="mobile-slide"
        animate={{
          x: `calc(-${props.currentSlide * 100}% - ${
            props.currentSlide * 2
          }rem)`,
        }}
        transition={{ ease: "easeOut", duration: 0.2 }}
        key={props.index}
      >
        <div
          style={{
            backgroundImage: `url(${props.coverBgImage})`,
          }}
          className="card-body"
        >
          <div className="gradient"></div>
          {props.children}

          <Box
            as="button"
            bg={"var(--primary-500)"}
            zIndex={9000}
            borderRadius={"0.38rem"}
            color={"var(--gray-100)"}
            fontFamily={"Istok Web"}
            fontWeight={"600"}
            fontSize={"1rem"}
            paddingInline={"1.5rem"}
            paddingBlock={"0.5rem"}
            display={"flex"}
            gap={"0.5rem"}
            _hover={{ bg: "var(--primary-600)" }}
            onClick={() => {
              props.setCurrentSlide(props.currentSlide + 1);
            }}
          >
            Learn More {<ArrowRight className="arrow-right" />}
          </Box>
        </div>
      </motion.div>
    );
  } else {
    return (
      <motion.div
        className="mobile-slide"
        animate={{
          x: `calc(-${props.currentSlide * 100}% - ${
            props.currentSlide * 2
          }rem)`,
        }}
        transition={{ ease: "easeOut", duration: 0.2 }}
        key={props.index}
      >
        <div
          className="mobile-slide-image"
          style={{ backgroundImage: `url(${props.leftImage})` }}
        ></div>
        <div className="mobile-slide-body">{props.children}</div>
      </motion.div>
    );
  }
}
