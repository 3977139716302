import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Impressum from "./components/Impressum";
import config from "./config.json";

function App() {
  // const [content, setContent] = useState<ReactNode>(<></>);

  // useEffect(() => {
  //   fetch("https://mikebryl.com:5025/Mail/Test", {
  //     method: "GET",
  //   }).then((response) => {
  //     if (response.ok) {
  //       setContent(<Main />);
  //     } else return;
  //   });
  // }, []);

  return <Main></Main>;
}

export default App;
